export const educations = [
  {
    id: 1,
    title: "BE - Computer Science and Engineering",
    duration: "2018 - 2022",
    institution: "Cape Institute Of Technology",
  },
  // {
  //   id: 2,
  //   title: "Higher Secondary Certificate",
  //   duration: "2016 - 2018",
  //   institution: "SLB Higher Secondary School",
  // },
  // {
  //   id: 3,
  //   title: "Secondary School Certificate",
  //   duration: "2014 - 2016",
  //   institution: "DVD Higher Secondary School",
  // }
]