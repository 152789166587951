// "use client";

// import React from "react";
// import Lottie from "lottie-react";

// const GreetingLottie = ({ animationPath }) => {
// 	const defaultOptions = {
// 		loop: true,
// 		autoplay: true,
// 		path: animationPath,
// 	};

// 	return (
// 		<div onClick={() => null}>
// 			<Lottie options={defaultOptions} />
// 		</div>
// 	);
// };

// export default GreetingLottie;

"use client";

import Lottie from "lottie-react";

const AnimationLottie = ({ animationPath, width }) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationPath,
    style: {
      width: '95%',
    }
  };

  return (
    <Lottie {...defaultOptions} />
  );
};

export default AnimationLottie;