import React from 'react';
import { Link } from 'react-router-dom';
import { personalData } from './personal-data'; // Adjust path as necessary
import { BsGithub, BsLinkedin } from 'react-icons/bs';
import { FaFacebook, FaTwitterSquare } from 'react-icons/fa';
import { MdDownload } from 'react-icons/md';
import { RiContactsFill } from 'react-icons/ri';
import { SiLeetcode } from 'react-icons/si';
import GreetingLottie from './DisplayLottie'; // Adjust path as necessary
import code from './coding.json';
import Contact from './Contact';
import StarButton from './StarButton';
import SocialMediaLinks from './SocialMediaLinks';
import ProfileCard from './ProfileCard';
import Hello from './Hello';
import Icon from './Icon';

function HeroSection() {
  const aboutMeLines = [
    "❤ Hello!, This is Adarsh Kumaran.",
    // "I love coding and design.",
    // "Welcome to my personal website!"
];
  return (
    <section className="relative flex flex-col items-center justify-between py-4 lg:py-12">
      {/* <img
        src="/hero.svg"
        alt="Hero"
        width={1572}
        height={795}
        className="absolute -top-[98px] -z-10"
      /> */}

      <div className="grid grid-cols-1 items-start lg:grid-cols-2 lg:gap-12 gap-y-8">
        <div className="order-1 lg:order-2 flex flex-col items-start justify-center p-2 pb-20 md:pb-10 lg:pt-10">
          <div className='animate-charcter'>
          <h1 className="text-3xl font-serif underline decoration-solid leading-10 text-black md:font-extrabold lg:text-[2.6rem] lg:leading-[3.5rem]">
            Hello , <br />
            This is{' '}
            <span className="text-pink-500">{personalData.name}</span>
            {`, I'm a Professional `}
            <span className="text-[#9c27b0]">{personalData.designation}</span>
            .
          </h1>
          </div>
          {/* <Hello txt={aboutMeLines}/> */}
          

          <div className="my-12 flex items-center gap-5">
          <Icon alignment="left" />
            {/* <SocialMediaLinks /> */}
            {/* <a
              href={personalData.github}
              target="_blank"
              rel="noopener noreferrer"
              className="transition-all text-black hover:scale-125 duration-300"
            >
              <BsGithub size={30} />
            </a>
            <a
              href={personalData.linkedIn}
              target="_blank"
              rel="noopener noreferrer"
              className="transition-all text-blue-500 hover:scale-125 duration-300"
            >
              <BsLinkedin size={30} />
            </a>
            <a
              href={personalData.facebook}
              target="_blank"
              rel="noopener noreferrer"
              className="transition-all text-blue-800 hover:scale-125 duration-300"
            >
              <FaFacebook size={30} />
            </a> */}
            {/* <a
              href={personalData.leetcode}
              target="_blank"
              rel="noopener noreferrer"
              className="transition-all text-pink-500 hover:scale-125 duration-300"
            >
              <SiLeetcode size={30} />
            </a> */}
            {/* <a
              href={personalData.twitter}
              target="_blank"
              rel="noopener noreferrer"
              className="transition-all text-blue-600 hover:scale-125 duration-300"
            >
              <FaTwitterSquare size={30} />
            </a> */}
          </div>

          <div className="flex items-center gap-3">
            <a
              href="/#contact"
              className="bg-gradient-to-r to-pink-500 from-violet-600 p-[1px] rounded-full transition-all duration-300 hover:from-pink-500 hover:to-violet-600"
            >
            {/* <StarButton /> */}
              {/* <Contact /> */}
              <button className="px-3 text-xs md:px-8 py-3 md:py-4 bg-[#0d1224] rounded-full border-none text-center md:text-sm font-medium uppercase tracking-wider text-[#ffff] no-underline transition-all duration-200 ease-out  md:font-semibold flex items-center gap-1 hover:gap-3">
                <span>Contact me</span>
                <RiContactsFill size={16} />
              </button>
            </a>

            <a
              href={personalData.resume}
              target="_blank"
              rel="noopener noreferrer"
              // className="flex items-center gap-1 hover:gap-3 rounded-full bg-gradient-to-r from-black to-black px-3 md:px-8 py-3 md:py-4 text-center text-xs md:text-sm font-medium uppercase tracking-wider text-white no-underline transition-all duration-200 ease-out hover:text-white hover:no-underline md:font-semibold"
            >
              <StarButton />
              {/* <span>Get Resume</span> */}
              {/* <MdDownload size={16} /> */}
            </a>
          </div>
        </div>

        <div className="order-2 lg:order-1">
          <GreetingLottie animationPath={code} />
        </div>
      </div>
    </section>
  );
}

export default HeroSection;