export const skillsData = [
  'Javascript',
  'React',
  'Tailwind',
  'Git',
  'AWS',
  'Docker',
  'Nginx',
  'Ansible',
  'Apache',
  'Cpanel',
  'Github',
  'Gitlab',
  'Grafana',
  'Prometheus',
  'Postman',
  'Spring',
  'Redis',
  'Linux',
  'Letsencrypt',
  'Kubernetes',
  'Jenkins',
  'Java',
  'Python',
  'Azure',
  'GCP',
  'Terraform',
]