// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './NavbarSimple'; // Adjust the import based on your file structure
import Skills from './Skills';
import HeroSection from './HeroSection';
import About from './About';
import Footer from './Footer';
import Experience from './Experience';
import Education from './Education';
import Contact from './ContactUs';

function App() {
  return (
    <Router>
      <main className="min-h-screen relative mx-auto px-6 sm:px-12 lg:max-w-[70rem] xl:max-w-[76rem] 2xl:max-w-[92rem] text-white">
      <Navbar />
      <HeroSection />
      <About />
      <Experience />
      <Skills />
      <Education />
      <Contact />
      </main>
      <Footer />
      <Routes>
        {/* Define your routes here */}
        {/* <Route path="/" element={<HomePage />} /> */}
        {/* Add more routes as needed */}
      </Routes>
    </Router>
  );
}

export default App;
