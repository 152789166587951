export const experiences = [
  {
    id: 1,
    title: 'DevOps Engineer',
    company: "Turilytix.AI",
    duration: "(May 2024 - Present)"
  },
  {
    id: 2,
    title: "DevOps Engineer",
    company: "AK INFOPARK",
    duration: "(Dec 2023 - May 2024)"
  },
  {
    id: 3,
    title: "DevOps Engineer",
    company: "Althi Solutions",
    duration: "(Nov 2022 - Dec 2023)"
  }
]