import React from 'react';
import { FaEnvelope, FaLinkedin, FaTwitter, FaGithub } from 'react-icons/fa';
import { personalData } from './personal-data'; // Adjust path as necessary
import './ProfileCard.css'; // Assuming you'll create a CSS file for styles
import profile from './profile.jpeg';

const ProfileCard = () => {
  return (
    <div className="cards max-w-xs mx-auto p-4 bg-white shadow-lg transition-all duration-500 transform hover:scale-105">
      <button className="mail">
        <FaEnvelope />
      </button>
      <div className="profile-pic">
      <img
            src={profile}
            width={280}
            height={280}
            // alt="Abu Said"
            className="rounded-lg transition-all duration-1000 grayscale hover:grayscale-0 hover:scale-110 cursor-pointer"
      />
      </div>
      <div className="bottom">
        <div className="content">
          <span className="name">ADARSH KUMARAN</span>
          <span className="about-me">
          Hello , This is {personalData.name} I'm a Professional {personalData.designation}:{' '}
            {/* <a href="https://freecodez.com" target="_blank" rel="noopener noreferrer">
              https://freecodez.com
            </a> */}
          </span>
        </div>
        <div className="bottom-bottom">
          <div className="social-links-container">
            <a href="https://www.linkedin.com/in/adarsh-kumaran-840b1b23b/" target="_blank" rel="noopener noreferrer">
              <FaLinkedin />
            </a>
            <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
              <FaTwitter />
            </a>
            <a href="https://github.com/adarshkumaran" target="_blank" rel="noopener noreferrer">
              <FaGithub />
            </a>
          </div>
          <button className="button">CONTACT US</button>
        </div>
      </div>
    </div>
  );
};

export default ProfileCard;
