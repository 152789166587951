// // Hello.js
// import React from 'react';
// import './GlowCard.css'; // Make sure to create a CSS file for styling

// // https://freecodez.com
// const Glow = (props) => {
// 	return (
// 		// <div className="card" role="button">
// 		// 	<span className="glow"></span>
// 		// 	<div className="inner">
// 		// 		{props.children}
// 		// 	</div>
// 		// </div>
//         /* From Uiverse.io by Mizohack */ 
// <div class="wrapper">
//   <div class="display">
//   {props.children}

//     <div id="time"></div>
//   </div>
//   <span></span>
//   <span></span>
// </div>

// 	);
// };


// export default Glow;

import React from 'react';
import './GlowCard.css'; // Ensure you have the required CSS

const Glow = (props) => {
  return (
    <div className="wrapper">
      <div className="display">
        {props.children}
        <div id="time"></div>
      </div>
      <span></span>
      <span></span>
    </div>
  );
};

export default Glow;
