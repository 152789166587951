// Hello.js
import React from 'react';
import './GlowCard.css'; // Make sure to create a CSS file for styling

// https://freecodez.com
const NewGlow = (props) => {
	return (
		<div className="card" role="button">
			<span className="glow"></span>
			<div className="inner">
				{props.children}
			</div>
		</div>
	);
};


export default NewGlow;