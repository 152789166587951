// @flow strict

import { personalData } from "./personal-data";
import profile from './profile.jpeg';
import ProfileCard from "./ProfileCard";
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Typography,
    Button,
  } from "@material-tailwind/react";

function AboutSection() {
    // return (
    //     <Card className="mt-6 w-96">
    //       <CardHeader color="blue-gray" className="relative h-56">
    //         <img
    //           src="https://images.unsplash.com/photo-1540553016722-983e48a2cd10?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=800&q=80"
    //           alt="card-image"
    //         />
    //       </CardHeader>
    //       <CardBody>
    //         <Typography variant="h5" color="blue-gray" className="mb-2">
    //           UI/UX Review Check
    //         </Typography>
    //         <Typography>
    //           The place is close to Barceloneta Beach and bus stop just 2 min by
    //           walk and near to &quot;Naviglio&quot; where you can enjoy the main
    //           night life in Barcelona.
    //         </Typography>
    //       </CardBody>
    //       <CardFooter className="pt-0">
    //         <Button>Read More</Button>
    //       </CardFooter>
    //     </Card>
    //   );
  return (
    <div id="about" className="my-12 lg:my-16 relative">
      <div className="hidden lg:flex flex-col items-center absolute top-16 -right-8">
        <span className="bg-[#1a1443] w-fit text-white rotate-90 p-2 px-5 text-xl rounded-md">
          ABOUT ME
        </span>
        <span className="h-36 w-[2px] bg-[#1a1443]"></span>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 lg:gap-16">
        <div className="order-2 lg:order-1">
          <p className="font-medium mb-5 text-[#16f2b3] text-xl uppercase">
            Who I am?
          </p>
          <p className="text-gray-200 text-sm lg:text-lg">
            {personalData.description}
          </p>
        </div>
        <div className="order-1 lg:order-2">
          {/* <img
            src={profile}
            width={280}
            height={280}
            alt="Abu Said"
            className="rounded-lg transition-all duration-1000 grayscale hover:grayscale-0 hover:scale-110 cursor-pointer"
          /> */}
          <ProfileCard />
        </div>
      </div>
    </div>
  );
};

export default AboutSection;