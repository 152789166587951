import React from "react";
import {
  Navbar,
  Collapse,
  Typography,
  IconButton,
} from "@material-tailwind/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { Avatar, Badge } from "@material-tailwind/react";
import data from "./logo.jpg"
 
function NavList() {
  return (
    <ul className="my-2 flex flex-col gap-2 lg:mb-0 lg:mt-0 lg:flex-row lg:items-center lg:gap-6">
      <Typography
        as="li"
        variant="small"
        color="blue-gray"
        className="p-1 font-medium"
      >
        <a href="#" className="flex items-center hover:text-blue-500 transition-colors">
          Pages
        </a>
      </Typography>
      <Typography
        as="li"
        variant="small"
        color="blue-gray"
        className="p-1 font-medium"
      >
        <a href="#" className="flex items-center hover:text-blue-500 transition-colors">
          Account
        </a>
      </Typography>
      <Typography
        as="li"
        variant="small"
        color="blue-gray"
        className="p-1 font-medium"
      >
        <a href="#" className="flex items-center hover:text-blue-500 transition-colors">
          Blocks
        </a>
      </Typography>
      <Typography
        as="li"
        variant="small"
        color="blue-gray"
        className="p-1 font-medium"
      >
        <a href="#" className="flex items-center hover:text-blue-500 transition-colors">
          Docs
        </a>
      </Typography>
    </ul>
  );
}
 
export function NavbarSimple() {
  const [openNav, setOpenNav] = React.useState(false);
 
  const handleWindowResize = () =>
    window.innerWidth >= 960 && setOpenNav(false);
 
  React.useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
 
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);
 
  return (
    <nav className="bg-transparent">
    <div className="flex items-center justify-between py-5">
      <div className="flex flex-shrink-0 items-center">
      {/* <Badge placement="top-end" overlap="circular" color="green" withBorder> */}
      <Avatar
        src={data}
        alt="avatar"
        withBorder={true}
        className="p-0.5 mr-4"
      />
      {/* </Badge> */}
        <a href="/" className="text-[#22d3ee] text-3xl font-bold">
          ADARSH KUMARAN
        </a>
      </div>

      <ul className="mt-4 flex h-screen max-h-0 w-full flex-col items-start text-sm opacity-0 md:mt-0 md:h-auto md:max-h-screen md:w-auto md:flex-row md:space-x-1 md:border-0 md:opacity-100" id="navbar-default">
        <li>
          <a href="/#about" className="block px-4 py-2 no-underline outline-none hover:no-underline">
            <div className="text-sm text-white transition-colors duration-300 hover:text-pink-600">ABOUT</div>
          </a>
        </li>
        <li>
          <a href="/#experience" className="block px-4 py-2 no-underline outline-none hover:no-underline">
            <div className="text-sm text-white transition-colors duration-300 hover:text-pink-600">EXPERIENCE</div>
          </a>
        </li>
        <li>
          <a href="/#skills" className="block px-4 py-2 no-underline outline-none hover:no-underline">
            <div className="text-sm text-white transition-colors duration-300 hover:text-pink-600">SKILLS</div>
          </a>
        </li>
        <li>
          <a href="/#education" className="block px-4 py-2 no-underline outline-none hover:no-underline">
            <div className="text-sm text-white transition-colors duration-300 hover:text-pink-600">EDUCATION</div>
          </a>
        </li>
        {/* Uncomment if needed */}
        {/* <li>
          <a href="/blog" className="block px-4 py-2 no-underline outline-none hover:no-underline">
            <div className="text-sm text-white transition-colors duration-300 hover:text-pink-600">BLOGS</div>
          </a>
        </li>
        <li>
          <a href="/#projects" className="block px-4 py-2 no-underline outline-none hover:no-underline">
            <div className="text-sm text-white transition-colors duration-300 hover:text-pink-600">PROJECTS</div>
          </a>
        </li> */}
      </ul>
    </div>
  </nav>
  );
}

export default NavbarSimple;
