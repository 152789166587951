// Hello.js
import React from 'react';
import './GlowCard.css'; // Make sure to create a CSS file for styling
import { FaInstagram, FaLinkedin, FaGithub, FaTelegramPlane, FaDiscord, FaWhatsapp, FaFacebookF } from 'react-icons/fa';

// https://freecodez.com
const Icon = ({ align }) => {
  const alignmentClass = align === 'center' ? 'cardq-center' : 'cardq-left';
	return (
    <div className={`cardq ${alignmentClass}`}>
      <a className="social-link1" href="https://www.instagram.com/adarsh_kumaran?igsh=amU0aW55YWk5eXUx" target="_blank" rel="noopener noreferrer">
        <FaInstagram size={24} />
      </a>
      <a className="social-link2" href="https://www.linkedin.com/in/adarsh-kumaran-840b1b23b/" target="_blank" rel="noopener noreferrer">
        <FaLinkedin size={24} />
      </a>
      <a className="social-link3" href="https://github.com/adarshkumaran" target="_blank" rel="noopener noreferrer">
        <FaGithub size={24} />
      </a>
      <a className="social-link4" href="https://t.me/+917339241312" target="_blank" rel="noopener noreferrer">
        <FaTelegramPlane size={24} />
      </a>
      <a className="social-link5" href="https://discordapp.com/users/adarsh_143" target="_blank" rel="noopener noreferrer">
        <FaDiscord size={24} />
      </a>
      <a className="social-link6" href="https://wa.me/+917339241312" target="_blank" rel="noopener noreferrer">
        <FaWhatsapp size={24} />
      </a>
      {/* <a className="social-link7" href="https://facebook.com" target="_blank" rel="noopener noreferrer">
        <FaFacebookF size={24} />
      </a> */}
    </div>
	);
};


export default Icon;
