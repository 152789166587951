import aws from './skills/aws.svg';
import azure from './skills/azure.svg';
import docker from './skills/docker.svg';
import gcp from './skills/gcp.svg';
import git from './skills/git.svg';
import java from './skills/java.svg';
import javascript from './skills/javascript.svg';
import nginx from './skills/nginx.svg';
import python from './skills/python.svg';
import react from './skills/react.svg';
import tailwind from './skills/tailwind.svg';
import ansible from './skills/ansible.svg';
import apache from './skills/apache.svg';
import cpanel from './skills/cpanel.svg';
import github from './skills/github.svg';
import gitlab from './skills/gitlab.svg';
import grafana from './skills/grafana.svg';
import prometheus from './skills/prometheus.svg';
import postman from './skills/postman.svg';
import spring from './skills/spring.svg';
import redis from './skills/redis.svg';
import linux from './skills/linux.svg';
import letsencrypt from './skills/letsencrypt.svg';
import kubernetes from './skills/kubernetes.svg';
import jenkins from './skills/jenkins.svg';
import terraform from './skills/terraform.svg';

export const skillsImage = (skill) => {
  const skillID = skill.toLowerCase();
  switch (skillID) {
    case 'gcp':
      return gcp;
    case 'docker':
      return docker;
    case 'javascript':
      return javascript;
    case 'react':
      return react;
    case 'tailwind':
      return tailwind;
    case 'java':
      return java;
    case 'python':
      return python;
    case 'aws':
      return aws;
    case 'git':
      return git;
    case 'nginx':
      return nginx;
    case 'azure':
      return azure;
    case 'jenkins':
      return jenkins;
    case 'kubernetes':
      return kubernetes;
    case 'letsencrypt':
      return letsencrypt;
    case 'linux':
      return linux;
    case 'redis':
      return redis;
    case 'spring':
      return spring;
    case 'postman':
      return postman;
    case 'prometheus':
      return prometheus;
    case 'grafana':
      return grafana;
    case 'gitlab':
      return gitlab;
    case 'github':
      return github;
    case 'cpanel':
      return cpanel;
    case 'apache':
      return apache;
    case 'ansible':
      return ansible;
    case 'terraform':
      return terraform;
    default:
      break;
  }
}