export const personalData = {
    name: "ADARSH KUMARAN",
    profile: './profile.jpeg',
    designation: "DevOps Engineer",
    description: "I’m Adarsh Kumaran, a passionate DevOps engineer with extensive experience in cloud platforms. With a strong self-learning attitude, I’m always eager to explore new technologies and continually enhance my skills. Problem-solving drives me, and I thrive on the challenges DevOps brings to modern software development. My focus is on optimizing systems to be more reliable, scalable, and efficient. I’m open to job opportunities that align with my expertise and interests, where I can contribute meaningfully to driving innovation and improvement.",
    // description: "As a Java Spring Boot Developer, I, ADARSH KUMARAN, specialize in building high-performance, scalable web applications. My experience with Spring Boot, along with my knowledge of other Spring modules like Spring Data and Spring Security, enables me to develop secure, maintainable solutions that meet business needs. I am passionate about leveraging Spring Boot's capabilities to streamline development and improve application performance.",
    email: 'adarshkumaran1@gmail.com',
    phone: '+91 7339241312',
    address: 'State -	Tamil Nadu , District	- Kanyakumari',
    github: 'https://www.adarshkumaran.com',
    facebook: 'https://www.adarshkumaran.com',
    linkedIn: 'https://www.adarshkumaran.com',
    twitter: 'https://www.adarshkumaran.com',
    stackOverflow: 'https://www.adarshkumaran.com',
    leetcode: "https://www.adarshkumaran.com",
    devUsername: "adarsh",
    resume: "https://drive.google.com/file/d/1xl6k07WHIDf2uOEs80yEQnbDnicmDcTr/view?usp=drivesdk"
    // resume: "https://drive.google.com/file/d/1eyMUSHDd-386oC0hY03RLlGXSX0q9qoQ/view?usp=drivesdk"
  }